/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState,useEffect,useRef } from "react";
import { AsyncPaginate } from 'react-select-async-paginate';
import {base64encode, base64decode} from "nodejs-base64";
import useIsMounted from 'react-is-mounted-hook';
import * as Realm from "realm-web";
import { GuardSpinner } from "react-spinners-kit";
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap";






function User() {
  const axios = require('axios').default;
  const APP_ID = process.env.REACT_APP_APP_ID;
  const BASE_URL = process.env.REACT_APP_REALM_HOSTING_ROOT;
  const REALM_TOKENCHECK_ENDPOINT = process.env.REACT_APP_REALM_TOKENCHECK_ENDPOINT;
  const AUTOCOMPLETE_ENDPOINT = process.env.REACT_APP_AUTOCOMPLETE_ENDPOINT;
  const FORM_SUBMIT_ENDPOINT = process.env.REACT_APP_FORM_SUBMIT_ENDPOINT;
  const MSIDE_DATA_ENDPOINT = process.env.REACT_APP_MSIDE_USER;
  const app = new Realm.getApp(APP_ID);
  const codeRequestedDate = moment().add(0,'days').format('YYYY-MM-DD');
  const codeExpirationDate = moment().add(90, 'days').format('YYYY-MM-DD');
  // use same page for redirect
  if(window.location.search && String(window.location.search).includes("?redirect=1")){
    try{
      Realm.handleAuthRedirect();
      checkIfTokenIsValid();
    }catch(e){
      console.log('couldnt handle redirect from oauth',e);
    }
  }else{
    console.log('not a redirect from oauth');
  }
  const preparePayload = function(){
    // clear previous form error/success status
    setFormError(false);
    setHasError(false);
    setSuccess(false);
    setWorking(true);
    let Payload = {
      "sales_rep":Buffer.from(salesRep, 'utf-8').toString(),
      "nwl_owner":Buffer.from(nwlOwner, 'utf-8').toString(),
      "sfdc_link":Buffer.from(sfdcLink, 'utf-8').toString(),
      "company":Buffer.from(company, 'utf-8').toString().replace(/[^\w\s]/gi, ''),
      "opp_amount":oppAmount,
      "atlas_amount":atlasCredits,
      "product":product,
      "is_jumbo":jumbo,
      "approver_1": decodedSession['mgr1_email']+"@mongodb.com",
      "approver_2": decodedSession['mgr2_email']+"@mongodb.com",
      "date_req":codeRequestedDate,//today
      "date_exp":codeExpirationDate,//+90days (Code Use Redemption Date)
    };
    console.log('pre-prepare',Payload);
    let err = {};
    if(String(Payload.sfdc_link).includes("https://mongodb.lightning.force.com/") || String(Payload.sfdc_link).includes("https://mongodb.my.salesforce.com/")){
      //we good
    }else{
      err['field'] = "NWL Link";
    }
    if(String(Payload.company).length === 0){
      err['field'] = "Company";
    }
    if(parseInt(Payload.opp_amount,10) < 0){
      err['field'] = "Opportunity Amount";
    }
    if(parseInt(Payload.atlas_amount,10) === 0){
      err['field'] = "Atlas Credits";
    }
    if(String(Payload['nwl_owner']) === ""){
      Payload['nwl_owner'] = decodedSession['full_user_email'];
    }
    if(String(Payload.sales_rep) === ""){
      err['field'] = "Sales Rep";
    }
    if(String(Payload.sales_rep) === "someone@mongodb.com"){
      err['field'] = "Sales Rep";
    }
    if(err['field']){
      return {"_err":err};
    }//if no form error, return the payload
    return Payload;
  }
  const tryToLoginWithGoogle = async function(){

    try{
      // The redirect URI should be on the same domain as this app and
      // specified in the auth provider configuration.
      const credentials = Realm.Credentials.google(BASE_URL+"?redirect=1");
      const realmUser = await app.logIn(credentials);
      // The logIn() promise will not resolve until you call `handleAuthRedirect()`
      // from the new window after the user has successfully authenticated.
      console.log('realmUser',realmUser);
      setInitializing(true);
      const userData = await axios.get(MSIDE_DATA_ENDPOINT+"&email="+realmUser._profile.data.email + "&chain=1&photo=1");
      console.log('userData',userData);
    
      sessionStorage.setItem('_utitle',base64encode(userData.data.data.title));
      sessionStorage.setItem('_ru',base64encode((realmUser._accessToken)));
      sessionStorage.setItem('_rue',base64encode((realmUser._profile.data.email)));
      sessionStorage.setItem('_rup',(userData.data.media.user));
      sessionStorage.setItem('_rume1',base64encode(userData.data.data.manager1.email));
      sessionStorage.setItem('_rump1',(userData.data.media.manager1));
      sessionStorage.setItem('_rume2',base64encode(userData.data.data.manager2.email));
      sessionStorage.setItem('_rump2',(userData.data.media.manager2));
      
      checkOnlineStatus();
    }catch(e){
      console.log('e',e);
    }

  };

  async function loadOptionsA(search, loadedOptions) {
    if(search !== ""){
      const sfEmails = await axios.get(AUTOCOMPLETE_ENDPOINT+"&email="+encodeURIComponent(search))
      console.log('emailz',sfEmails);
      let sf_emails = [];
      sfEmails['data'].forEach((e)=>{
        if(e["username"] !== "" && String(e["username"]).includes(search)){
          sf_emails.push({"value":e["username"],"label":e["username"]});
        }
      });
      console.log('sf_emails',sf_emails);
      return {
        options: sf_emails,
        hasMore: false,
      };
    }else{
      return {options:[],hasMore:false};
    }

  }


  const sendEmail = function(newUrl){
    console.log('trying to send email',newUrl+"@mongodb.com");
    window.open("mailto:"+newUrl+"@mongodb.com", '_blank').focus();
  };
  const loadYesNoOptions = function(){
    return {
      "options":[{value:false,label:"No"},{value:true,label:"Yes"}],
      "hasMore":false
    };
  };
  const productOptions = function(){
    return {
      "options": [
        {value:"Atlas Commercial",label:"Atlas Commercial"},
        {value:"Atlas Government",label:"Atlas Government"},
        {value:"Cloud Manager",label:"Cloud Manager"}
      ],
      "hasMore":false
    }
  }
  const checkIfTokenIsValid = function(){
    axios.post(
      REALM_TOKENCHECK_ENDPOINT,
      {token:String(base64decode(sessionStorage.getItem('_ru')))}
      ).then((res)=>{
        if(res.data && res.data.token_email !== ""){
          setInitializing(false);
          setLoginStatus(true);
        }else{
          setLoginStatus(false);
        }
      }).catch((e)=>{
        console.log('e',e.message)
        setLoginStatus(false);
      });
  };
  const [isLoggedIn,setLoginStatus] = useState(false);
  const checkOnlineStatus = function(){
    if(sessionStorage.getItem('_ru')){
      checkIfTokenIsValid();
    }else{
      setLoginStatus(false);
    }
  };

  //default to owning the NWL
  const [nwlOwner, setNWLOwner] = useState("");
  const [salesRep,setSalesRep] = useState("");
  const [product,setProduct] = useState("Atlas Commercial");
  const selected_PO = useRef({"value":"someone@mongodb.com","label":"someone@mongodb.com"});
  const selected_SR = useRef({"value":"someone@mongodb.com","label":"someone@mongodb.com"});
  const selected_Product = useRef({"value":"Atlas Commercial","label":"Atlas Commercial"});
  // JUMBO
  // So what is a jumbo deployment?  
  // Basically anything that could present a challenge due to it's size/complexity. 
  const selected_JUMBO = useRef({"value":false,"label":"No"});
  const [jumbo,setJUMBO] = useState(false); 
  
  const onchangeSelectPO = (item) => {
    console.log('selected item',item);
    if(item){
      selected_PO.current = item;
      setNWLOwner(item.value);
    }else{
      let po_email = decodedSession['full_user_email']?decodedSession['full_user_email']:"someone@mongodb.com";
      selected_PO.current = {"value":po_email,"label":po_email};
      setNWLOwner("");
    }
  };
  const onchangeSelectSR = (item) => {
    console.log('selected item',item);
    if(item){
      selected_SR.current = item;
      setSalesRep(item.value);
    }else{
      selected_SR.current = {"value":"someone@mongodb.com","label":"someone@mongodb.com"};
      setSalesRep("");
    }
  };
  const onchangeSelectProduct = (item) => {
    console.log('selected item',item);
    if(item){
      selected_Product.current = item;
      setProduct(item.value);
    }
  };
  const onchangeSelectJUMBO = (item) =>{
    if(item){
      selected_JUMBO.current = item;
      setJUMBO(item.value);
    }else{
      selected_JUMBO.current = {"value":false,"label":"No"};
      setJUMBO(false);
    }
  };
  const tryToLogout = ()=>{
    sessionStorage.clear();
    window.location.reload(true);
  };

  const [sfdcLink,setsfdcLink] = useState("");
  const [company,setCompany] = useState("");
  const [oppAmount,setOppAmount] = useState(0);
  const [atlasCredits,setAtlasCredits] = useState(0);
  // Modal open state
  const [modal, setModal] = React.useState(false);
  // Toggle for Modal
  const toggleModal = () =>{
    setModal(!modal);
  };

  useEffect(() => {
    console.log('modal state changed!',modal);

    if(modal === true){
      //open
      let Payload = preparePayload();
      if(typeof Payload['_err'] !== 'undefined'){
        //something went wrong at form level - fail quickly
        setWorking(false);
        setErrorField(Payload['_err']['field']);
        setFormError(true);
      }else{
        setFormError(false);
        setErrorField("");
      }
      //append realmUser token to Payload
      Payload['_ru'] = decodedSession['token'];
      Payload['_rue'] = decodedSession['full_user_email'] ;
      console.log('Payload--->',Payload);
      if(Payload['_err']){
        // handle form error further?
      }else{
        axios.post(FORM_SUBMIT_ENDPOINT,Payload).then((res)=>{
          if(res.data && res.data.token_email === ""){
            alert('funny business with the token. for security purposes please re-authenticate.');
            //force re-auth
            tryToLogout();
          }else if(res.data.data && res.data.data.key){
            setJIRAUrl("https://jira.mongodb.org/browse/"+res.data.data.key);
            setWorking(false);
            setSuccess(true);
          }else{
            setWorking(false);
            setHasError(true);
          }
          console.log('Issue submission was attempted. Check logs for more details.');
        }).catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

        });
      }

    }
  }, [modal]);
  const [error_field,setErrorField] = useState("");
  const [newJiraURL,setJIRAUrl] = useState("");

  const [hasError,setHasError] = useState(false);
  const [hasFormError,setFormError] = useState(false);
  const [hasSuccess,setSuccess] = useState(false);
  const [isWorking,setWorking] = useState(true);

  const [isInitializing,setInitializing] = useState(false);
  useEffect(() => {
    if(sessionStorage.getItem('_ru')){
      checkOnlineStatus();
    }
    const interval = setInterval(() => {
      checkOnlineStatus();
    }, 2000);
    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    }
  }, [useIsMounted]);
  let decodedSession = {};
  if(isLoggedIn){
    console.log('isLoggedIn')
    //decode session storage
    decodedSession['token'] = String(base64decode(sessionStorage.getItem('_ru')));
    decodedSession['user_profile_pic'] = (sessionStorage.getItem('_rup'));
    decodedSession['full_user_email'] = String(base64decode(sessionStorage.getItem('_rue')));
    decodedSession['user_email'] = String(base64decode(sessionStorage.getItem('_rue'))).split("@")[0];
    decodedSession['mgr1_pic'] = (sessionStorage.getItem('_rump1'));
    decodedSession['mgr1_email'] = String(base64decode(sessionStorage.getItem('_rume1'))).split("@")[0];
    decodedSession['mgr2_pic'] = (sessionStorage.getItem('_rump2'));
    decodedSession['mgr2_email'] = String(base64decode(sessionStorage.getItem('_rume2'))).split("@")[0];
    decodedSession['title'] = String(base64decode(sessionStorage.getItem('_utitle')));
    if(nwlOwner === ""){
      selected_PO.current = {"value":decodedSession['full_user_email'], "label":decodedSession['full_user_email']};
    }
    if(salesRep === ""){
      selected_SR.current = {"value":"someone@mongodb.com", "label":"someone@mongodb.com"};
    }
  }
  return (
    <>
      <div className="content">
      {isLoggedIn ? (
        <Row>
        <Col md="4">
          <Card className="card-user">
            <div className="image">
              <img
                alt="..."
                src={require("assets/img/bg-mdb.png").default}
              />
            </div>
            <CardBody>
              <div className="author">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="..."
                    className="avatar border-gray"
                    src={decodedSession["user_profile_pic"]}
                  />
                  <p class="description">{decodedSession["user_email"]}</p>
                </a>
                <p className="user-title">
                  <b>{decodedSession['title']}</b>
                </p>
                <p><Button size="sm" id="logout-link" color="danger" outline onClick={() => tryToLogout()}>logout</Button></p>
              </div>

            </CardBody>

          </Card>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Management Chain</CardTitle>
            </CardHeader>
            <CardBody>
              <ul className="list-unstyled team-members">
                <li>
                  <Row>
                    <Col md="2" xs="2">
                      <div className="avatar">
                        <img
                          alt="..."
                          className="img-circle img-no-padding img-responsive"
                          src={decodedSession["mgr1_pic"]}
                        />
                      </div>
                    </Col>
                    <Col md="7" xs="7">
                      {decodedSession["mgr1_email"]} <br />
                      <span className="text-muted">
                        <small>1st Mgr</small>
                      </span>
                    </Col>
                    <Col className="text-right" md="3" xs="3">
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        outline
                        size="sm"
                        onClick={()=>{sendEmail(decodedSession["mgr1_email"])}}
                      >
                        <i className="fa fa-envelope" />
                      </Button>
                    </Col>
                  </Row>
                </li>

              <li>&nbsp;</li>




                <li>
                  <Row>
                    <Col md="2" xs="2">
                    <div className="avatar">
                        <img
                          alt="..."
                          className="img-circle img-no-padding img-responsive"
                          src={decodedSession["mgr2_pic"]}
                        />
                      </div>
                    </Col>
                    <Col md="7" xs="7">
                      {decodedSession["mgr2_email"]} <br />
                      <span className="text-muted">
                        <small>2nd Mgr</small>
                      </span>
                    </Col>
                    <Col className="text-right" md="3" xs="3">
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        outline
                        size="sm"
                        onClick={()=>{sendEmail(decodedSession["mgr2_email"])}}
                      >
                        <i className="fa fa-envelope" />
                      </Button>
                    </Col>
                  </Row>
                </li>

              <li>&nbsp;</li>


              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">Request MDB ATLAS Credits</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-1" md="11">
                    <FormGroup>
                      <label>SA Requester</label>
                      <AsyncPaginate
                          value={selected_PO.current}
                          placeholder={decodedSession['full_user_email']}
                          loadOptions={loadOptionsA}
                          onChange={(e) => {onchangeSelectPO(e)}}
                          isClearable={true}
                        />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="11">
                    <FormGroup>
                      <label>Sales Rep</label>
                      <AsyncPaginate
                          value={selected_SR.current}
                          loadOptions={loadOptionsA}
                          onChange={(e) => {onchangeSelectSR(e)}}
                          placeholder="someone@mongodb.com"
                          isClearable={true}
                        />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="11">
                    <FormGroup>
                        <label>What type of Atlas Product is this for?</label>
                          <AsyncPaginate
                            value={selected_Product.current}
                            loadOptions={productOptions}
                            onChange={e => onchangeSelectProduct(e)}
                            isClearable={true}
                          />
                      </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="11">
                    <FormGroup>
                        <label className="qtip tip-top" data-tip="So what is a jumbo deployment?  Basically anything that could present a challenge due to it's size/complexity.  Examples:  75 shards of M80s, 10 x M200s in Tokyo,  60 M30s with global writes. A jumbo deployment can be a net new deployment, a customer run POC, an upgrade of an existing environment, etc.  Use your best judgement.">Is this a JUMBO deployment? </label>
                          <AsyncPaginate
                            value={selected_JUMBO.current}
                            loadOptions={loadYesNoOptions}
                            onChange={e => onchangeSelectJUMBO(e)}
                            isClearable={true}
                          />
                      </FormGroup>
                  </Col>
                </Row>
                <Row>
                <Col className="pr-1" md="11">
                    <FormGroup>
                      <label>SFDC NWL Link</label>
                      <Input
                        value={sfdcLink}
                        placeholder="https://<url>"
                        type="url"
                        onChange={e => setsfdcLink(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                <Col className="pr-1" md="11">
                    <FormGroup>
                      <label>Company</label>
                      <Input
                        placeholder="Creative Code Inc."
                        type="text"
                        value={company}
                        onChange={e => setCompany(String(e.target.value).replace(/[^\w\s]/gi, ''))}
                        maxlength="10"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="11">
                    <FormGroup>
                      <label>Opportunity Amount</label>
                      <br />
                    <CurrencyInput
                        value={oppAmount}
                        placeholder="$USD"
                        defaultValue={0}
                        decimalsLimit={2}
                        prefix={"$"}
                        className="form-control"
                        style={{width: "100%"}}
                        onValueChange={(value, name) => {
                          console.log('name',name,'value',value);
                          if(value && value >= 0){
                            setOppAmount(value);
                          }else{
                            setOppAmount(0);
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="11">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">
                        Atlas Credits Requested
                      </label>
                        <br />
                      <CurrencyInput
                          value={atlasCredits}
                          placeholder="$USD"
                          defaultValue={0}
                          decimalsLimit={2}
                          prefix={"$"}
                          className="form-control"
                          style={{width: "100%"}}
                          onValueChange={(value, name) => {
                            console.log('name',name,'value',value);
                            if(value && value > 0){
                              setAtlasCredits(value);
                            }else{
                              setAtlasCredits(0);
                            }
                          }}
                        />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto">
                  {(jumbo) && 
                        <div class="alert alert-warning" role="alert" style={{width:"103.5%",display:"inline-block"}}>
                          <p style={{"display":"inline-block","paddingLeft":"1rem"}}>
                          <span style={{"fontSize":"2rem",paddingRight:".25em"}}>
                            <i className="nc-icon nc-alert-circle-i" />
                          </span> 
                          You have marked this as a jumbo deployment, this requires you to also fill out <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUYmAtpIZkkODJ8TgGF9V4XjjG9OixM59oc4kdzVWhnsqBXg/viewform" target="_blank" rel="noopener noreferrer">this form</a> so the appropriate teams are aware. 
                          
                          </p>
                        </div>                      
                      }
                    <br /><br /><hr />

                  <Button color="primary"
                    onClick={toggleModal}>Submit
                  </Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={modal}
                modalTransition={{ timeout: 100 }}>
               <ModalHeader
                    toggle={toggleModal}>
                      PSCodes -
                      {isWorking &&
                        <span>Working...</span>
                      }
                      {hasError &&
                        <span>Something went wrong</span>
                      }
                      {hasFormError &&
                        <span>FORM ERROR</span>
                      }
                      {hasSuccess &&
                        <span>Success</span>
                      }

                </ModalHeader>
                <ModalBody>
                  {isWorking &&
                    <div id="spinner-section">
                      <GuardSpinner size={150} loading={{loading:true}} />
                    </div>
                  }
                  {hasError &&
                    <div id="error-message-generic">
                      <p>Please try again. If this problem continues, contact us.</p>
                    </div>
                  }
                  {hasFormError &&
                    <div id="error-message-form">
                      <p>Please enter a valid value for: <br />{error_field}.</p>
                    </div>
                  }
                  {hasSuccess &&
                    <div id="success-message">
                      <p>Your request has been successfully submitted.</p>
                      <p>
                        Date Requested: <br/>{codeRequestedDate}
                      </p>
                      <p>
                        Must be redeemed by:<br/>{codeExpirationDate}
                      </p>
                      <p>You can find it here:</p>
                      <p><a href={newJiraURL} target="_blank" rel="noopener noreferrer">{newJiraURL}</a></p>
                    </div>
                  }

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleModal}>Okay</Button>
                </ModalFooter>
            </Modal>
      </Row>
      ) : (
        <Row>
          <Col sm="12">
            <div>
            {isInitializing &&
              <div id="initial-spinner-section">
                <GuardSpinner size={150} loading={{loading:true}} />
              </div>
            }
            {!isInitializing &&
            
              <div>                        
                <p>
                  You must be logged in to continue. <a id="login-link" href="#" onClick={() => tryToLoginWithGoogle()}>Click here to login</a>
                </p>
              </div>
            }
              
            </div>
          </Col>

        </Row>
      )}

      </div>
    </>
  );
}

export default User;
